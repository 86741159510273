body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: whitesmoke !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.css-mhc70k-MuiGrid-root {
  box-sizing: border-box !important;
  display: flex !important;
  flex-wrap: wrap !important;
  flex-direction: row !important;
  margin-top: auto !important;
  width: auto !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

.css-mhc70k-MuiGrid-root > .MuiGrid-item {
  padding-right: 16px !important;
}
