* {
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

.MuiBox-root.css-flr9ij {
  display: none;
}

.MuiBox-root.css-636293 {
  display: flex;
  justify-content: center;
  }

.css-178yklu {
  display: flex;
  justify-content: flex-end;
}

/* Barra di scorrimento verticale */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: rgb(35, 140, 216);
  border-radius: 5px;
}

/* Barra di scorrimento orizzontale */
::-webkit-scrollbar-horizontal {
  width: 8px;
}

::-webkit-scrollbar-thumb:horizontal {
  background: rgb(35, 140, 216);
  border-radius: 5px;
}

::-webkit-scrollbar-track:horizontal {
  background: #f1f1f1;
}

/* Border radius at table */
.prettyTable th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.prettyTable th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px
}

/* sortable table label and arrow icon override */
.tableSortLabel, .MuiTableSortLabel-icon {
  color: white !important;
}

/* table cell default values right style */
.tableCell {
  max-width: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* table cell code value right style */
.tableCellCode {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Socket toast messages */
.spanSocketToastMex {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  font-weight: 600;
  font-size: 16px;
  color: white;
}

.socketNotificationRefresh {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  color: white;
  cursor: pointer;
}

.socketNotificationRefresh svg {
  margin-right: 5px;
}

.socketNotificationDropdown hr {
  border: 0.1px solid rgba(255, 255, 255, 0.728);
  margin: 18px 0 18px 0;
}

.spanSocketToastMex svg {
  margin-left: 8px;
  margin-right: 10px;
  min-width: 20px;
  width: 20px;
  height: auto;
  fill: #ffffff;
}

.spanSocketToastUser {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  font-weight: 600;
  font-size: 16px;
  color: white;
}

.spanSocketToastUser svg {
  margin-left: 6px;
  margin-right: 10px;
  min-width: 20px;
  height: auto;
  fill: #ffffff;
}

.props1divSocketToastMex {
  margin-top: 10px;
  margin-left: 15px;
  color: white;
}

.propsSocketToastMex {
  display: flex;
  margin-top: 8px;
  color: white;
}

.keySocketToastMex {
  font-weight: bold;
  font-size: 15px;
}

.valueSocketToastMex {
  font-weight: 600;
  font-size: 16px;
  margin-left: 0.2em;

}

.socketToastDate {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: white;
}

.socketToastDate svg {
  margin-right: 5px;
  font-size: 16px;

}

.socketNotificationDropdown {
  background-color: #3498db;
  min-width: 270px;
  padding: 15px;
}

.socketNotificationDropdown h6 {
  color: white;
  text-align: center;
}

.notificationDropdown ul[role='menu'] {
  padding: 0px !important;
}